
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import {
  ICandidato, ICandidatoCurso, ICandidatoExperienciaProfissional, ICandidatoReferenciaPessoal,
} from '@/models/Entidades/ICandidato';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoCandidato from '@/servicos/ServicoCandidato';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import { IResposta } from '@/core/models/IResposta';
import Card from '@/core/components/UI/Card.vue';
import CampoCep from '@/core/components/UI/CampoCep.vue';
import CampoCpf from '@/core/components/UI/CampoCpf.vue';
import CampoTelefone from '@/core/components/UI/CampoTelefone.vue';
import ComboCidade from '@/components/ComboCidade.vue';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import ComboGenero from '@/components/ComboGenero.vue';
import SelecionarData from '@/core/components/UI/SelecionarData.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ComboMaoDominante from '@/components/ComboMaoDominante.vue';
import ComboGrauInstrucao from '@/components/ComboGrauInstrucao.vue';
import ComboSetor from '@/components/ComboSetor.vue';
import ComboStatusCandidato from '@/components/ComboStatusCandidato.vue';
import ComboTempo from '@/components/ComboTempo.vue';
import ComboEstadoCivil from '@/components/ComboEstadoCivil.vue';
import SelecionarSimNao from '@/core/components/UI/SelecionarSimNao.vue';
import storeApp from '@/store/storeApp';

export default defineComponent({
  name: 'candidatoCadastro',
  components: {
    ComunicacaoApi,
    Card,
    CampoCep,
    CampoCpf,
    CampoTelefone,
    ComboCidade,
    CampoEmail,
    SelecionarData,
    ComboGenero,
    ComboMaoDominante,
    ComboGrauInstrucao,
    ComboSetor,
    ComboStatusCandidato,
    ComboTempo,
    ComboEstadoCivil,
    SelecionarSimNao,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicocandidato = new ServicoCandidato();
    const servicoUtilitario = new ServicoUtilitario();
    const state = reactive({
      candidato: {} as ICandidato,
      chaveEndereco: '0',
      cep: '',
      cpfCnpj: '',
    });

    function limparDados() {
      state.candidato = {} as ICandidato;
      state.candidato.codigo = 0;
      state.candidato.genero = 1;
      state.candidato.maoDominante = 1;
      state.candidato.status = 1;
      state.candidato.estadoCivil = 1;
      state.candidato.possuiDeficiencia = false;
      state.candidato.referenciasPessoais = [];
      state.candidato.cursos = [];
      state.candidato.experienciaisProfissionais = [];
      state.cpfCnpj = '';
      state.cep = '';
    }

    async function obtercandidato(codigo:number) {
      appBase.carregando = true;
      state.candidato = await servicocandidato.obter(codigo);
      state.cpfCnpj = state.candidato.cpf;

      appBase.carregando = false;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigocandidato = obterCodigoRota();
      if (codigocandidato > 0) {
        await obtercandidato(codigocandidato);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;

      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do candidato.');
      if (state.candidato.codigo === 0) {
        appBase.resposta = await servicocandidato.incluir(state.candidato);
      } else {
        appBase.resposta = await servicocandidato.atualizar(state.candidato);
      }

      apresentarRespostaRedirecionamento('Candidatos');
    }

    async function consultaCep() {
      if (UtilitarioGeral.valorValido(state.candidato.cep) && UtilitarioGeral.validaValorComLimiteCaracteres(state.candidato.cep, 8)) {
        if (state.candidato.cep !== state.cep) {
          const retornoConsultaCep = await servicoUtilitario.consultaCep(state.candidato.cep);
          if (retornoConsultaCep !== undefined) {
            state.candidato.endereco = retornoConsultaCep.logradouro;
            state.candidato.complemento = retornoConsultaCep.complemento;
            state.candidato.bairro = retornoConsultaCep.bairro;
            state.candidato.codigoCidade = retornoConsultaCep.codigoCidade;
          }
        }
      }
    }

    function adicionarReferenciaPessoal() {
      const referencialPessoal: ICandidatoReferenciaPessoal = {} as ICandidatoReferenciaPessoal;
      referencialPessoal.codigo = 0;
      referencialPessoal.codigoCandidato = 0;
      referencialPessoal.nome = '';
      referencialPessoal.telefone = '';
      state.candidato.referenciasPessoais.push(referencialPessoal);
    }

    function removerReferenciaPessoal(index:number) {
      state.candidato.referenciasPessoais.splice(index, 1);
    }

    function adicionarCurso() {
      const curso: ICandidatoCurso = {} as ICandidatoCurso;
      curso.codigo = 0;
      curso.codigoCandidato = 0;
      curso.curso = '';
      curso.duracao = 0;
      curso.tempo = 'Horas';
      curso.anoConclusao = 2024;
      state.candidato.cursos.push(curso);
    }

    function removerCurso(index:number) {
      state.candidato.cursos.splice(index, 1);
    }

    function adicionarExperienciaProfissional() {
      const experiencia: ICandidatoExperienciaProfissional = {} as ICandidatoExperienciaProfissional;
      experiencia.codigo = 0;
      experiencia.codigoCandidato = 0;
      experiencia.empresa = '';
      experiencia.cargo = '';
      state.candidato.experienciaisProfissionais.push(experiencia);
    }

    function removerExperienciaProfissional(index:number) {
      state.candidato.experienciaisProfissionais.splice(index, 1);
    }

    function obtemTituloExperiencia(index: number) {
      if (index === 0) {
        return 'Último emprego';
      } if (index === 1) {
        return 'Penúltimo emprego';
      } if (index === 2) {
        return 'Antepenúltimo emprego';
      }

      return `${index + 1}ª Experiência`;
    }

    return {
      appBase,
      storeApp,
      state,
      salvar,
      consultaCep,
      UtilitarioGeral,
      adicionarReferenciaPessoal,
      removerReferenciaPessoal,
      adicionarCurso,
      removerCurso,
      adicionarExperienciaProfissional,
      removerExperienciaProfissional,
      obtemTituloExperiencia,
    };
  },
});


import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/UI/Icone.vue';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IDTOCandidato } from '@/models/DTO/IDTOCandidato';
import ServicoCandidato from '@/servicos/ServicoCandidato';
import { ICandidatoParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/ICandidatoParametrosConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import ComboSetor from '@/components/ComboSetor.vue';

export default defineComponent({
  name: 'CandidatoListagem',
  components: {
    Icone,
    ComunicacaoApi,
    ComboSetor,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta, apresentarMensagemAlerta,
    } = useAppBase();
    const servicoCandidato = new ServicoCandidato();

    const state = reactive({
      Candidatos: [] as IDTOCandidato[],
      parametrosConsulta: {} as ICandidatoParametrosConsulta,
      apresentarFiltros: false,
      ordenarPor: 'CodigoCandidatoDESC',
      opcoesOrdenacao: [] as IOptionString[],
    });
    let debounce = 0;

    state.opcoesOrdenacao = [];
    const ordemMaisRecentes:IOptionString = { value: 'CodigoCandidatoDESC', label: 'Mais recentes' } as IOptionString;
    const ordemNomeAsc:IOptionString = { value: 'NomeCandidatoASC', label: 'Nome (A-Z)' } as IOptionString;
    const ordemNomeDesc:IOptionString = { value: 'NomeCandidatoDESC', label: 'Nome (Z-A)' } as IOptionString;
    state.opcoesOrdenacao.push(ordemMaisRecentes);
    state.opcoesOrdenacao.push(ordemNomeAsc);
    state.opcoesOrdenacao.push(ordemNomeDesc);

    async function buscarDados() {
      appBase.carregando = true;
      state.apresentarFiltros = false;
      state.Candidatos = [];
      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      if (appBase.paginacao.paginaAtual > 0) { state.parametrosConsulta.numeroPagina = appBase.paginacao.paginaAtual; }
      if (appBase.paginacao.qtdeRegistrosPagina > 0) { state.parametrosConsulta.qtdeRegistrosPagina = appBase.paginacao.qtdeRegistrosPagina; }
      state.parametrosConsulta.qtdeRegistrosTotal = 100;
      const respostaConsulta = await servicoCandidato.obterCandidatos(state.parametrosConsulta);
      if (UtilitarioGeral.validaLista(respostaConsulta.dados)) {
        state.Candidatos = respostaConsulta.dados;
        appBase.paginacao = respostaConsulta.paginacao;
      }

      appBase.carregando = false;
    }

    function buscarCandidatoPorNome() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarDados();
      }, 600);
    }
    async function limparFiltros() {
      state.parametrosConsulta.status = undefined;
      await buscarDados();
    }
    onBeforeMount(async () => {
      await buscarDados();
    });

    async function paginacaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = current;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }
    async function qtdeRegistrosPaginaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = 1;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }

    async function deletarCandidato(codigo: number) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor...');
      appBase.resposta = await servicoCandidato.deletar(codigo);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        const index = state.Candidatos.findIndex((u) => u.codigo === codigo);
        if (index >= 0) {
          state.Candidatos.splice(index, 1);
        }
      }
      apresentarResposta();
    }
    function confirmaExclusao(Candidato:IDTOCandidato) {
      Modal.confirm({
        title: 'Você confirma a exclusão do Candidato:',
        content: `${Candidato.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await deletarCandidato(Candidato.codigo); },
      });
    }

    function obtemDescricaoStatus(status:number):string {
      let descricao = '';
      switch (status) {
        case 1:
          descricao = 'Aguardando Revisão';
          break;

        case 2:
          descricao = 'Cadastro Aprovado';
          break;

        case 3:
          descricao = 'Cadastro Incompleto';
          break;

        case 4:
          descricao = 'Contratado';
          break;

        case 5:
          descricao = 'Reprovado';
          break;
        default:
          break;
      }
      return descricao;
    }

    async function imprimirFicha(codigo:number) {
      const retornoRelatorio = await servicoCandidato.imprimirFicha(codigo);
      if (retornoRelatorio.status === EStatusResposta.Sucesso) {
        window.open(retornoRelatorio.link, '_blank');
      } else if (retornoRelatorio.status === EStatusResposta.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusResposta.Erro) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      }
    }

    return {
      appBase,
      state,
      UtilitarioMascara,
      buscarDados,
      limparFiltros,
      buscarCandidatoPorNome,
      paginacaAlterada,
      qtdeRegistrosPaginaAlterada,
      confirmaExclusao,
      obtemDescricaoStatus,
      imprimirFicha,
    };
  },
});

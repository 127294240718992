
import {
  defineComponent, onBeforeMount, reactive, ref, watch,
} from 'vue';
import { useElementSize } from '@vueuse/core';
import { useRoute } from 'vue-router';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import storeApp from '@/store/storeApp';
import { useAppBase } from '@/core/composables/AppBase';
import Card from '@/core/components/UI/Card.vue';
import SelecionarData from '@/core/components/UI/SelecionarData.vue';
import CampoCep from '@/core/components/UI/CampoCep.vue';
import CampoCpf from '@/core/components/UI/CampoCpf.vue';
import CampoTelefone from '@/core/components/UI/CampoTelefone.vue';
import ComboCidade from '@/components/ComboCidade.vue';
import ServicoCandidato from '@/servicos/ServicoCandidato';
import GerenciadorAutenticacao from '@/core/gerenciadores/GerenciadorAutenticacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import {
  ICandidato, ICandidatoCurso, ICandidatoExperienciaProfissional, ICandidatoReferenciaPessoal,
} from '@/models/Entidades/ICandidato';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import { ETipoControleArea } from '@/models/Enumeradores/ETipoControle';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import ComboGenero from '@/components/ComboGenero.vue';
import ComboMaoDominante from '@/components/ComboMaoDominante.vue';
import ComboGrauInstrucao from '@/components/ComboGrauInstrucao.vue';
import ComboSetor from '@/components/ComboSetor.vue';
import ComboTempo from '@/components/ComboTempo.vue';
import ComboEstadoCivil from '@/components/ComboEstadoCivil.vue';
import SelecionarSimNao from '@/core/components/UI/SelecionarSimNao.vue';

export default defineComponent({
  name: 'CandidatoPublico',
  components: {
    ComunicacaoApi,
    Card,
    SelecionarData,
    ComboGenero,
    ComboMaoDominante,
    ComboGrauInstrucao,
    CampoEmail,
    CampoCpf,
    CampoCep,
    CampoTelefone,
    ComboCidade,
    ComboSetor,
    ComboTempo,
    ComboEstadoCivil,
    SelecionarSimNao,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();
    const el = ref(null);
    const { height } = useElementSize(el);
    const route = useRoute();
    const servicoCandidato = new ServicoCandidato();
    const servicoUtilitario = new ServicoUtilitario();
    const gerenciadorAutenticacao = new GerenciadorAutenticacao();
    const state = reactive({
      cadastroRelizadoComSucesso: false,
      candidato: {} as ICandidato,
      codigoContratante: '',
      aceiteTermo: false,
    });

    function enviarAtualizacaoAltura(height:number) {
      window.parent.postMessage({ height }, '*');
    }

    async function consultaCep() {
      if (UtilitarioGeral.valorValido(state.candidato.cep) && UtilitarioGeral.validaValorComLimiteCaracteres(state.candidato.cep, 8)) {
        const retornoConsultaCep = await servicoUtilitario.consultaCep(state.candidato.cep);
        if (retornoConsultaCep !== undefined) {
          if (!UtilitarioGeral.valorValido(state.candidato.endereco)) {
            state.candidato.endereco = retornoConsultaCep.logradouro;
          }

          if (!UtilitarioGeral.valorValido(state.candidato.complemento)) {
            state.candidato.complemento = retornoConsultaCep.complemento;
          }

          state.candidato.bairro = retornoConsultaCep.bairro;
          state.candidato.codigoCidade = retornoConsultaCep.codigoCidade;
        }
      }
    }

    function adicionarReferenciaPessoal() {
      const referencialPessoal: ICandidatoReferenciaPessoal = {} as ICandidatoReferenciaPessoal;
      referencialPessoal.codigo = 0;
      referencialPessoal.codigoCandidato = 0;
      referencialPessoal.nome = '';
      referencialPessoal.telefone = '';
      state.candidato.referenciasPessoais.push(referencialPessoal);
    }

    function removerReferenciaPessoal(index:number) {
      state.candidato.referenciasPessoais.splice(index, 1);
    }

    function adicionarCurso() {
      const curso: ICandidatoCurso = {} as ICandidatoCurso;
      curso.codigo = 0;
      curso.codigoCandidato = 0;
      curso.curso = '';
      curso.duracao = 0;
      curso.tempo = 'Horas';
      curso.anoConclusao = 2024;
      state.candidato.cursos.push(curso);
    }

    function removerCurso(index:number) {
      state.candidato.cursos.splice(index, 1);
    }

    function adicionarExperienciaProfissional() {
      const experiencia: ICandidatoExperienciaProfissional = {} as ICandidatoExperienciaProfissional;
      experiencia.codigo = 0;
      experiencia.codigoCandidato = 0;
      experiencia.empresa = '';
      experiencia.cargo = '';
      state.candidato.experienciaisProfissionais.push(experiencia);
    }

    function removerExperienciaProfissional(index:number) {
      state.candidato.experienciaisProfissionais.splice(index, 1);
    }

    function obtemTituloExperiencia(index: number) {
      if (index === 0) {
        return 'Último emprego';
      } if (index === 1) {
        return 'Penúltimo emprego';
      } if (index === 2) {
        return 'Antepenúltimo emprego';
      }

      return '';
    }

    function limparDados() {
      state.candidato = {} as ICandidato;
      state.candidato.codigo = 0;
      state.candidato.genero = 1;
      state.candidato.estadoCivil = 1;
      state.candidato.maoDominante = 1;
      state.candidato.referenciasPessoais = [];
      state.candidato.cursos = [];
      state.candidato.possuiDeficiencia = false;
      adicionarCurso();
      adicionarCurso();
      adicionarCurso();
      state.candidato.experienciaisProfissionais = [];
      adicionarExperienciaProfissional();
      adicionarExperienciaProfissional();
      adicionarExperienciaProfissional();
      state.candidato.status = 1;
    }

    onBeforeMount(async () => {
      limparDados();

      if (route.params !== undefined && UtilitarioGeral.validaCodigo(route.params.parceiro)) {
        gerenciadorAutenticacao.salvarIdentificadorParceiro(route.params.parceiro.toString());
        state.codigoContratante = route.params.parceiro.toString();
      }

      storeApp.mutations.atualizarParceiroAutenticado(storeApp.getters.obterParceiroAutenticado(route.params.parceiro.toString()));
      document.documentElement.style.setProperty('--primary', storeApp.state.parceiroAutenticado.corPrimaria);
      document.documentElement.style.setProperty('--primary-light', storeApp.state.parceiroAutenticado.corSecundaria);
      document.documentElement.style.setProperty('--primary-dark', storeApp.state.parceiroAutenticado.corEscura);

      if (state.codigoContratante === '2') {
        adicionarReferenciaPessoal();
        adicionarReferenciaPessoal();
        adicionarReferenciaPessoal();
      }
    });

    watch(height, (value) => {
      enviarAtualizacaoAltura(value);
    },
    { deep: true, immediate: true });

    async function salvar() {
      appBase.resposta = {} as IResposta;

      apresentarComunicacaoApi('Aguarde por favor...');
      if (state.candidato.codigo === 0) {
        appBase.resposta = await servicoCandidato.incluir(state.candidato);
        if (appBase.resposta.status === EStatusResposta.Sucesso) {
          appBase.exibirComunicaoApi = false;
          state.cadastroRelizadoComSucesso = true;
        } else {
          apresentarResposta();
        }
      }
    }

    return {
      appBase,
      state,
      el,
      height,
      ETipoControleArea,
      UtilitarioGeral,
      UtilitarioMascara,
      consultaCep,
      salvar,
      adicionarReferenciaPessoal,
      removerReferenciaPessoal,
      adicionarCurso,
      removerCurso,
      adicionarExperienciaProfissional,
      removerExperienciaProfissional,
      obtemTituloExperiencia,
    };
  },
});

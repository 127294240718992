
import {
  computed, defineComponent, reactive, onBeforeMount,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useAppBase } from '@/core/composables/AppBase';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoGrauInstrucao from '@/servicos/ServicoGrauInstrucao';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';

export default defineComponent({
  name: 'ComboGrauInstrucao',
  props: {
    multiplaSelecao: {
      type: Boolean,
      default: false,
    },
    codigo: {
      type: Number || undefined,
    },
    codigos: {
      type: Array as () => number[],
      default: () => [],
    },
    valorSelecionado: {
      type: Number,
      default: 0,
    },
    ignorarCodigos: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigo', 'update:codigos', 'update:valorSelecionado', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesBuscaRapida } = useAppBase();

    let debounce = 0;
    const servicoGrauInstrucao = new ServicoGrauInstrucao();

    const state = reactive({
      opcoesGrauInstrucoes: [] as IOption[],
      itensBuscaRapida: [] as IItemBuscaRapida[],
      buscando: false,
      digitando: false,
    });

    async function obterPorCodigo(valor: any) {
      if (state.digitando) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }

      if (props.multiplaSelecao) {
        if (props.codigos.length === 0) { return; }
      } else if (props.codigo === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.opcoesGrauInstrucoes)) {
        if (props.multiplaSelecao) {
          const listaCodigosConsulta = state.opcoesGrauInstrucoes.map((c) => c.value);
          const diferencas = props.codigos.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.opcoesGrauInstrucoes.find((c) => c.value === props.codigo);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.opcoesGrauInstrucoes = [];
      const parametrosBuscaRapida: IParametrosBuscaRapida = { apenasAtivos: false };

      if (props.multiplaSelecao) {
        parametrosBuscaRapida.filtrarPorVariosCodigos = true;
        parametrosBuscaRapida.valores = valor;
      } else {
        parametrosBuscaRapida.filtrarPorCodigo = true;
        parametrosBuscaRapida.valor = valor;
      }

      state.itensBuscaRapida = await servicoGrauInstrucao.buscaRapida(parametrosBuscaRapida);
      state.opcoesGrauInstrucoes = montaOpcoesBuscaRapida(state.itensBuscaRapida);
      if (!UtilitarioGeral.validaLista(state.opcoesGrauInstrucoes)) {
        if (props.multiplaSelecao) {
          emit('update:codigos', undefined);
        } else {
          emit('update:codigo', undefined);
        }
      }
    }

    async function pesquisar(valor: any) {
      state.digitando = true;

      state.buscando = true;
      state.opcoesGrauInstrucoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosBuscaRapida: IParametrosBuscaRapida = { valor, apenasAtivos: true };

        state.itensBuscaRapida = await servicoGrauInstrucao.buscaRapida(parametrosBuscaRapida);
        if (props.ignorarCodigos.length > 0) {
          props.ignorarCodigos.forEach((codigo) => {
            state.itensBuscaRapida = state.itensBuscaRapida.filter((c) => c.codigo !== codigo);
          });
        }
        state.buscando = false;
        state.opcoesGrauInstrucoes = montaOpcoesBuscaRapida(state.itensBuscaRapida);
      }, 600);
    }

    const computedValorSelecionado = computed({
      get: () => props.valorSelecionado,
      set: (valor: number) => {
        emit('update:valorSelecionado', valor);
      },
    });

    function preencherValorSetor(codigoSetor:number) {
      const itemBuscaRapida = state.itensBuscaRapida.find((c) => c.codigo === codigoSetor);
      if (itemBuscaRapida !== undefined) {
        computedValorSelecionado.value = Number(itemBuscaRapida.informacaoAdicional);
      } else {
        computedValorSelecionado.value = 0;
      }
    }

    function obterSetor():any {
      obterPorCodigo(props.codigo);

      if (props.codigo !== undefined && props.codigo > 0) {
        preencherValorSetor(props.codigo);
      }
      return props.codigo;
    }

    const computedCodigo = computed({
      get: () => obterSetor(),
      set: (val: number) => {
        emit('update:codigo', val);
      },
    });

    function obterSetores():number[] {
      obterPorCodigo(props.codigos);
      return props.codigos;
    }

    const computedCodigos = computed({
      get: () => obterSetores(),
      set: (val: number[]) => {
        emit('update:codigos', val);
      },
    });

    function change() {
      emit('change', props.multiplaSelecao ? props.codigos : props.codigo);
    }

    onBeforeMount(async () => {
      state.opcoesGrauInstrucoes = [];
      const parametrosBuscaRapida: IParametrosBuscaRapida = { valor: '', apenasAtivos: true };
      state.itensBuscaRapida = await servicoGrauInstrucao.buscaRapida(parametrosBuscaRapida);
      if (props.ignorarCodigos.length > 0) {
        props.ignorarCodigos.forEach((codigo) => {
          state.itensBuscaRapida = state.itensBuscaRapida.filter((c) => c.codigo !== codigo);
        });
      }
      state.buscando = false;
      state.opcoesGrauInstrucoes = montaOpcoesBuscaRapida(state.itensBuscaRapida);
    });

    return {
      props,
      state,
      computedCodigo,
      computedCodigos,
      pesquisar,
      change,
    };
  },
});

import { vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    class: "ant-input",
    onPaste: _cache[0] || (_cache[0] = ($event: any) => (_ctx.change())),
    onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.valorDigitado($event))),
    onKeydown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.valorDigitado($event))),
    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.blur())),
    disabled: _ctx.props.disabled,
    placeholder: _ctx.props.placeholder,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedValor) = $event))
  }, null, 40, _hoisted_1)), [
    [_vModelText, _ctx.computedValor],
    [_directive_maska, _ctx.UtilitarioMascara.formatoMascaraCPF]
  ])
}
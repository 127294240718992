
import { computed, defineComponent } from 'vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'CampoCep',
  components: {
  },
  props: {
    valor: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'change', 'valorDigitado', 'blur'],
  setup(props, { emit }) {
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: string) => {
        if (valor === undefined || valor === null) {
          emit('update:valor', '');
        } else {
          const valorRetorno = UtilitarioMascara.removerMascara(valor);
          emit('update:valor', valorRetorno);
        }
      },
    });

    function change() {
      emit('change', UtilitarioMascara.removerMascara(computedValor.value));
    }
    function valorDigitado(event : any) {
      if ((event.keyCode >= 48 && event.keyCode <= 57)
    || (event.keyCode >= 96 && event.keyCode <= 105)
    || event.keyCode === 46 || event.keyCode === 110 || event.keyCode === 190
    || event.keyCode === 8 || event.keyCode === 194 || event.keyCode === 219) {
        emit('valorDigitado');
      }
    }

    function blur() {
      emit('blur');
    }

    return {
      props,
      computedValor,
      UtilitarioMascara,
      valorDigitado,
      change,
      emit,
      blur,
    };
  },
});
